import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const trainingClassInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const trainingClassListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

const trainingClassParticipantInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestTrainingClassList: "requestTrainingClassList",
  receiveTrainingClassList: "receiveTrainingClassList",
  errorRequestTrainingClassList: "errorRequestTrainingClassList",

  requestTrainingClassParticipant: "requestTrainingClassParticipant",
  receiveTrainingClassParticipant: "receiveTrainingClassParticipant",
  errorRequestTrainingClassParticipant: "errorRequestTrainingClassParticipant",
  
  requestTrainingClass: "requestTrainingClass",
  receiveTrainingClass: "receiveTrainingClass",
  modifyTrainingClass: "modifyTrainingClass",
  removeTrainingClass: "removeTrainingClass",
  errorModifyTrainingClass: "errorModifyTrainingClass",
  errorRequestTrainingClass: "errorRequestTrainingClass",
}

export const getTrainingClassList = (state) => {
  return state.admin.trainingClass.list
}

export const getTrainingClass = (state, key) => {
  if (key in state.admin.trainingClass.records)
    return state.admin.trainingClass.records[key]
  return null
}

export const getTrainingClassParticipant = (state, key) => {
  // if (key in state.trainingClass.participant)
    return state.admin.trainingClass.participant
  // return null
}

// Async action creator

export const fetchTrainingClassList = (refresh=false, params = null) => {
  return (dispatch, getState) => {
    let res = getTrainingClassList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().alpha.user
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/"

    if (params)
      url += Object.keys(params).map(key =>
        "?" + key + "=" + params[key]).join("")

    dispatch(requestTrainingClassList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClassList(json)))
      .catch(error => dispatch(errorRequestTrainingClassList(error)))
  }
}

export const fetchTrainingClass = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getTrainingClass(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      

    let user = getState().alpha.user
    
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/"
    
    dispatch(requestTrainingClass(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",            
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorRequestTrainingClass(key, error)));
  }
}

export const submit = (key, onComplete) => {
  return (dispatch, getState) => {

    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/submit/"

    let data = { }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTrainingClass(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const inviteTrainer = (key, trainerKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/invite_trainer/"
    
    let data = { uid: trainerKey }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const selectProposal = (key, proposalKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/select_proposal/"
    
    let data = { uid: proposalKey }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/create_order/"
    
    let data = { }  
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const updateTrainingClass = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/"
      
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTrainingClass(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))
  }
}

export const deleteTrainingClass = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/"
      
    // dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(response.status === 204){
          onComplete()
        }else{
            alert("ERROR")
        }
      })
      // .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => error)
  }
}

export const cancelTrainingClass = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + key + "/cancel/"
      
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTrainingClass(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))
  }
}

export const createTrainingClass = (createKey = "new", data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/"
    
    // createKey can be anything unique.
    
    dispatch(removeTrainingClass(createKey))
    dispatch(modifyTrainingClass(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        if(json.uid){
          dispatch(receiveTrainingClass(createKey, json))
          onComplete(json.uid)
        }else{
          dispatch(errorModifyTrainingClass(createKey,json.detail))
        }
      })
      .catch(error => dispatch(errorModifyTrainingClass(createKey, error)))
  }
}

export const updateBatchTrainingClass = (data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/batch_update/"
    
    // createKey can be anything unique.
    
    // dispatch(removeTrainingClass(createKey))
    // dispatch(modifyTrainingClass(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        if(json){
          dispatch(receiveTrainingClassList(json))
          onComplete()
        }else{
          dispatch(errorRequestTrainingClassList(json.detail))
        }
      })
      .catch(error => dispatch(errorRequestTrainingClassList(error)))
  }
}

export const fetchAvailableParticipant = (trainingClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/" + trainingClassKey + "/participants/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestTrainingClassParticipant(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainingClassParticipant(trainingClassKey,json)))
      .catch(error => dispatch(errorRequestTrainingClassParticipant(trainingClassKey, params, error)))
  }
};

// Synchronous Action Creators

export const requestTrainingClassList = () => ({
  type: ACTION.requestTrainingClassList,
})

export const receiveTrainingClassList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("trainingClassList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTrainingClassList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainingClassList = (error) => ({
  type: ACTION.errorRequestTrainingClassList,
  error
})

//participant
export const requestTrainingClassParticipant = () => ({
  type: ACTION.requestTrainingClassParticipant,
})

export const receiveTrainingClassParticipant = (key, json) => {

  let result = json
  
//  alert("TrainingClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveTrainingClassParticipant,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestTrainingClassParticipant = (error) => ({
  type: ACTION.errorRequestTrainingClassParticipant,
  error
})

export const modifyTrainingClass = (key) => ({
  type: ACTION.modifyTrainingClass,
  key
})

export const requestTrainingClass = (key) => ({
  type: ACTION.requestTrainingClass,
  key
})

export const receiveTrainingClass = (key, json) => {

  let result = json
  
//  alert("TrainingClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveTrainingClass,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeTrainingClass = (key) => ({
  type: ACTION.removeTrainingClass,
  key
})

export const errorModifyTrainingClass = (key, error) => ({
  type: ACTION.errorModifyTrainingClass,
  key,
  error
})

export const errorRequestTrainingClass = (key, error) => ({
  type: ACTION.errorRequestTrainingClass,
  key,
  error
})

// Reducers for Conversation

const trainingClassReducer = createReducer(trainingClassInitial, {
  [ACTION.requestTrainingClass]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyTrainingClass]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveTrainingClass]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestTrainingClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyTrainingClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const trainingClassListReducer = createReducer(trainingClassListInitial, {
  [ACTION.requestTrainingClassList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainingClassList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestTrainingClassList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const trainingClassParticipantReducer = createReducer(trainingClassParticipantInitial, {
  [ACTION.requestTrainingClassParticipant]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainingClassParticipant]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }      
    },
  [ACTION.errorRequestTrainingClassParticipant]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function trainingClassByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeTrainingClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: trainingClassReducer(state[key], action)
  }
}

const trainingClassByKeyReducer = createReducer({}, {
  [ACTION.requestTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.modifyTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.removeTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.receiveTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.errorRequestTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.errorModifyTrainingClass]: trainingClassByKeyUpdater,
})

// Combine list and records reducer

const trainingClassDataReducer = combineReducers({
  list: trainingClassListReducer,
  records: trainingClassByKeyReducer,
  participant: trainingClassParticipantReducer
});

export default trainingClassDataReducer