import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const contactInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const contactIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchContactIndex: "fetchContactIndex",
  requestContactIndex: "requestContactIndex",
  receiveContactIndex: "receiveContactIndex",
  errorRequestContactIndex: "errorRequestContactIndex",
  invalidateContactIndex: "invalidateContactIndex",
  fetchContact: "fetchContact",
  requestContact: "requestContact",
  receiveContact: "receiveContact",
  errorRequestContact: "errorRequestContact",
  invalidateContact: "invalidateContact",
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getContactIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.contact.indexes) {
    return state.contact.indexes[key]
  }
  
  return contactIndexInitial
}

export const fetchContactIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
    let user = getState().alpha.user
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/api/company/"+user.companyInfo.uid.toString()+"/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)
        
    dispatch(requestContactIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContactIndex(path, params, json)))
      .catch(error => dispatch(errorRequestContactIndex(path, params, error)));
  }
};

export const getContact = (state, recordKey) => {
//   if (recordKey in state.Contact.records)
//       return state.Contact.records[recordKey]

if (state.contact.records)
      return state.contact.records
  
  return contactInitial
}


export const fetchContact = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getContact(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/address/api/city/?state="+recordKey+"&country=ID"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestContact(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveContact(recordKey, params, json)))
      .catch(error => dispatch(errorRequestContact(recordKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateContactIndex = (path, params) => ({
  type: ACTION.invalidateContactindex,
  path
});

export const requestContactIndex = (path, params) => ({
  type: ACTION.requestContactIndex,
  path,
  params
});

export const receiveContactIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveContactIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestContactIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestContactIndex,
  recordKey,
  params,
  error
});

export const invalidateContact = (recordKey) => ({
  type: ACTION.invalidateContact,
  recordKey
});

export const requestContact = (recordKey, params) => ({
  type: ACTION.requestContact,
  recordKey,
  params
});

export const receiveContact = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveContact,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestContact = (recordKey, params, error) => ({
  type: ACTION.errorRequestContact,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const contactIndexReducer = createReducer(contactIndexInitial, {
  [ACTION.requestContactIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveContactIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestContactIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateContactIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function contactIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: contactIndexReducer(state[key], action)
  }
}

const contactIndexByKeyReducer = createReducer({}, {
  [ACTION.requestContactIndex]: contactIndexByKeyUpdater,
  [ACTION.receiveContactIndex]: contactIndexByKeyUpdater,
  [ACTION.errorReceiveContactIndex]: contactIndexByKeyUpdater,
  [ACTION.invalidateContactIndex]: contactIndexByKeyUpdater,
})


// Reducers for Product Record

const contactReducer = createReducer(contactInitial, {
  [ACTION.requestContact]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveContact]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestContact]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateContact]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function contactByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: contactReducer(state[key], action)
  }
}

const contactByKeyReducer = createReducer({}, {
  [ACTION.requestContact]: contactByKeyUpdater,
  [ACTION.receiveContact]: contactByKeyUpdater,
  [ACTION.errorRequestContact]: contactByKeyUpdater,
  [ACTION.invalidateContact]: contactByKeyUpdater,
})

// Combine all catalog reducer

const contactDataReducer = combineReducers({
  indexes: contactIndexByKeyReducer,
  records: contactByKeyReducer,
});

export default contactDataReducer