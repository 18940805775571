import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const courseRecordInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const courseIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

const topicIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchCourseIndex: "fetchCourseIndex",
  requestCourseIndex: "requestCourseIndex",
  receiveCourseIndex: "receiveCourseIndex",
  errorRequestCourseIndex: "errorRequestCourseIndex",
  invalidateCourseIndex: "invalidateCourseIndex",
  fetchTopicIndex: "fetchTopicIndex",
  requestTopicIndex: "requestTopicIndex",
  receiveTopicIndex: "receiveTopicIndex",
  errorRequestTopicIndex: "errorRequestTopicIndex",
  invalidateTopicIndex: "invalidateTopicIndex",
  fetchCourseRecord: "fetchCourseRecord",
  requestCourseRecord: "requestCourseRecord",
  receiveCourseRecord: "receiveCourseRecord",
  errorRequestCourseRecord: "errorRequestCourseRecord",
  invalidateCourseRecord: "invalidateCourseRecord",
  requestDownload: "requestDownload"
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  // if (params)
  //   key += Object.keys(params).map(key =>
  //     "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getCourseIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  
  if (key in state.client.course.indexes) {
    return state.client.course.indexes[key]
  }
  
  return courseIndexInitial
}

export const getCourseIndexAll = (state, path, params) => {
  if (state.client.course.indexes) {
    return state.client.course.indexes
  }
  
  return courseIndexInitial
}

export const fetchCourseIndex = (path, params=null, refresh=false, fetchMore=false) => {
  return (dispatch, getState) => {
    let res = getCourseIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh && !fetchMore))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/?key_path=" + path
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
//    alert("Fetching: " + url)

    dispatch(requestCourseIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => {
        if (fetchMore) {
          let res = getCourseIndex(getState(),path, null)
          let newItems = res.data.records.concat(json.courses)
          
          let newData = {
            total: json.total,
            categories: json.categories,
            offset: json.offset,
            courses: newItems
          }

          dispatch(receiveCourseIndex(path, params, newData))
        } else {
          dispatch(receiveCourseIndex(path, params, json))
        }
      })
      .catch(error => dispatch(errorRequestCourseIndex(path, params, error)));
  }
};

export const fetchCourseMore = (path, params=null, addMore) => {
  return (dispatch, getState) => {
    let res = getCourseIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/?key_path=" + path
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
//    alert("Fetching: " + url)

    dispatch(requestCourseIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => {
        addMore(json.courses)
        // dispatch(receiveCourseIndex(path, params, json))
      })
      .catch(error => dispatch(errorRequestCourseIndex(path, params, error)));
  }
};

export const getTopicIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.client.course.topics) {
    return state.client.course.topics[key]
  }
  
  return topicIndexInitial
}

export const fetchTopicIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getTopicIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"

    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/categories/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
//    alert("Fetching: " + url)

    dispatch(requestTopicIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTopicIndex(path, params, json)))
      .catch(error => dispatch(errorRequestTopicIndex(path, params, error)));
  }
};

export const getCourseRecord = (state, recordKey) => {
  if (recordKey in state.client.course.records)
      return state.client.course.records[recordKey]
  
  return courseRecordInitial
}

export const fetchCourseRecord = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getCourseRecord(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestCourseRecord(recordKey, params)); 
    return fetch(url, { 
              headers: { 
                'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
                ...authHeader
              }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveCourseRecord(recordKey, params, json)))
      .catch(error => dispatch(errorRequestCourseRecord(recordKey, params, error)));
  }
};

export const downloadCourseSyllabus = (courseId, onComplete) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/" + courseId + "/download/?document_type=course_syllabus"
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    

    dispatch(requestDownload(courseId, null)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            onComplete()
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          } else if (response.status == 404) {
            onComplete()
            throw new Error("Not Found")
          } else {
            onComplete()
            throw new Error(response.json())
          }
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateCourseIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestCourseIndex = (path, params) => ({
  type: ACTION.requestCourseIndex,
  path,
  params
});

export const receiveCourseIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.total || json['courses'].length,
    records: json['courses'],
    categories: json['categories']
  }
  
//  alert("receiveCourseIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveCourseIndex,
    path,
    // params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestCourseIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestCourseIndex,
  recordKey,
  params,
  error
});

export const invalidateTopicIndex = (path, params) => ({
  type: ACTION.invalidateTopicIndex,
  path
});

export const requestTopicIndex = (path, params) => ({
  type: ACTION.requestTopicIndex,
  path,
  params
});

export const receiveTopicIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json,
  }
  
//  alert("receiveCourseIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTopicIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTopicIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestTopicIndex,
  recordKey,
  params,
  error
});

export const invalidateCourseRecord = (recordKey) => ({
  type: ACTION.invalidateCourseRecord,
  recordKey
});

export const requestCourseRecord = (recordKey, params) => ({
  type: ACTION.requestCourseRecord,
  recordKey,
  params
});

export const receiveCourseRecord = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveCourseRecord,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestCourseRecord = (recordKey, params, error) => ({
  type: ACTION.errorRequestCourseRecord,
  recordKey,
  params,
  error
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

// Reducers for Product Index

const courseIndexReducer = createReducer(courseIndexInitial, {
  [ACTION.requestCourseIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveCourseIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestCourseIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateCourseIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function courseIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, null)
  return {
    ...state,
    [key]: courseIndexReducer(state[key], action)
  }
}

const courseIndexByKeyReducer = createReducer({}, {
  [ACTION.requestCourseIndex]: courseIndexByKeyUpdater,
  [ACTION.receiveCourseIndex]: courseIndexByKeyUpdater,
  [ACTION.errorReceiveCourseIndex]: courseIndexByKeyUpdater,
  [ACTION.invalidateCourseIndex]: courseIndexByKeyUpdater,
})

// Reducers for Topic

const topicIndexReducer = createReducer(topicIndexInitial, {
  [ACTION.requestTopicIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTopicIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTopicIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateTopicIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function topicIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: topicIndexReducer(state[key], action)
  }
}

const topicIndexByKeyReducer = createReducer({}, {
  [ACTION.requestTopicIndex]: topicIndexByKeyUpdater,
  [ACTION.receiveTopicIndex]: topicIndexByKeyUpdater,
  [ACTION.errorReceiveTopicIndex]: topicIndexByKeyUpdater,
  [ACTION.invalidateTopicIndex]: topicIndexByKeyUpdater,
})

// Reducers for Product Record

const courseRecordReducer = createReducer(courseRecordInitial, {
  [ACTION.requestCourseRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveCourseRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestCourseRecord]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateCourseRecord]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function courseRecordByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: courseRecordReducer(state[key], action)
  }
}

const courseRecordByKeyReducer = createReducer({}, {
  [ACTION.requestCourseRecord]: courseRecordByKeyUpdater,
  [ACTION.receiveCourseRecord]: courseRecordByKeyUpdater,
  [ACTION.errorReceiveCourseRecord]: courseRecordByKeyUpdater,
  [ACTION.invalidateCourseRecord]: courseRecordByKeyUpdater,
})

// Combine all catalog reducer

const courseReducer = combineReducers({
  indexes: courseIndexByKeyReducer,
  records: courseRecordByKeyReducer,
  topics: topicIndexByKeyReducer
});

export default courseReducer