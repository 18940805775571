import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const publicClassInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const publicClassListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

const paymentMethodInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

// Actions
const ACTION = {
  requestPublicClassList: "requestPublicClassList",
  receivePublicClassList: "receivePublicClassList",
  errorRequestPublicClassList: "errorRequestPublicClassList",
  
  requestPublicClass: "requestPublicClass",
  receivePublicClass: "receivePublicClass",
  modifyPublicClass: "modifyPublicClass",
  removePublicClass: "removePublicClass",
  errorModifyPublicClass: "errorModifyPublicClass",
  errorRequestPublicClass: "errorRequestPublicClass",

  requestPaymentMethod: "requestPaymentMethod",
  receivePaymentMethod: "receivePaymentMethod",
  errorRequestPaymentMethod: "errorRequestPaymentMethod"
}

export const getPublicClassList = (state) => {
  return state.client.publicClass.list
}

export const getPublicClass = (state, key) => {
  if (key in state.client.publicClass.records)
    return state.client.publicClass.records[key]
  return null
}

export const getPaymentMethod = (state, key) => {
  if (key in state.client.publicClass.paymentMethods)
      return state.client.publicClass.paymentMethods[key]
  
  return paymentMethodInitial
}

export const getTrainer = (state, recordKey) => {
  if (recordKey in state.client.trainer.records)
      return state.client.trainer.records[recordKey]
  
  return trainerInitial
}

export const requestTrainer = (recordKey, params) => ({
  type: ACTION.requestTrainer,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const requestState = (recordKey, params) => ({
  type: ACTION.requestState,
  recordKey,
  params
});

export const receiveTrainer = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveTrainer,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

export const receiveState = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveState,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestState = (recordKey, params, error) => ({
  type: ACTION.errorRequestState,
  recordKey,
  params,
  error
});

// Async action creator

export const fetchPublicClassList = (refresh=false, params = null) => {
  return (dispatch, getState) => {
    let res = getPublicClassList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/"

    if(params)
      url += Object.keys(params).map(key =>
        "?" + key + "=" + params[key]).join("")
  

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }    

    dispatch(requestPublicClassList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClassList(json)))
      .catch(error => dispatch(errorRequestPublicClassList(error)))
  }
}

export const fetchPaymentMethod = (trainingClassKey, refresh=false) => {
  return (dispatch, getState) => {
    let res = getPaymentMethod(getState(), trainingClassKey)
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + trainingClassKey + "/payment-methods/"    

    let user = getState().alpha.user  
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestPaymentMethod(trainingClassKey)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePaymentMethod(trainingClassKey, json)))
      .catch(error => dispatch(errorRequestPaymentMethod(trainingClassKey, error)));
  }
};

export const fetchPublicClass = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getPublicClass(getState(), key)
    // if (res && (res.isFetching || (res.lastFetched  && !refresh)))
    //   return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/"
      
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestPublicClass(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorRequestPublicClass(key, error)));
  }
}

export const purchaseTicket = (key, data, onComplete, onError) => {
  return (dispatch, getState) => {
  let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/purchase_ticket/"  

  let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo ? user.companyInfo.uid.toString() : ""
    }
  
  return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
        ...authHeader
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(json => {
      if(json.pay_url){
        onComplete()
        window.open(json.pay_url, "_self")
      }else if(json.order_no){
        onComplete()
      }else{
        onError()
        alert(json.detail)
      }
    })
    .catch(error => error) 
  }
}

export const submit = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/submit/"

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    let data = { }
    
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authHeader
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))          
  }
}

export const inviteTrainer = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/invite_trainer/"

    let user = getState().alpha.user
    
    let data = { uid: trainerKey }
    
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))          
  }
}

export const selectProposal = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/select_proposal/"

    let user = getState().alpha.user
    
    let data = { uid: proposalKey }
    
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))          
  }
}

export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/create_order/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))          
  }
}

export const cancelPublicClass = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/cancel/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))          
  }
}

export const updatePublicClass = (key, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/"

    let user = getState().alpha.user
      
    dispatch(modifyPublicClass(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorModifyPublicClass(key, error)))
  }
}

export const fetchAvailableTrainer = (publicClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainer(getState(), publicClassKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + publicClassKey + "/available_trainers/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestTrainer(publicClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainer(publicClassKey, params, json)))
      .catch(error => dispatch(errorRequestTrainer(publicClassKey, params, error)))
  }
};

export const downloadProposal = (publicClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + publicClassKey + "/download/?document_type=proposal"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(publicClassKey, params, error)))
  }
};

export const downloadProposalByProposalId = (publicClassKey, proposalId, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + publicClassKey + "/download/?document_type=proposal&proposal="+proposalId
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(publicClassKey, params, error)))
  }
};

export const downloadLetter = (publicClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + publicClassKey + "/download/?document_type=assignment_letter"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => dispatch(receiveTrainer(trainingClassKey, params, json)))
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const downloadReport = (publicClassKey, reportUid, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + publicClassKey + "/download/?document_type=report&report="+reportUid
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const createPublicClass = (createKey, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/?submit=1"

    let user = getState().alpha.user
    
    // createKey can be anything unique.
    
    dispatch(removePublicClass(createKey))
    dispatch(modifyPublicClass(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(createKey, json)))
      .catch(error => dispatch(errorModifyPublicClass(createKey, error)))
  }
}


// Synchronous Action Creators

export const requestPublicClassList = () => ({
  type: ACTION.requestPublicClassList,
})

export const receivePublicClassList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("PublicClassList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receivePublicClassList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestPublicClassList = (error) => ({
  type: ACTION.errorRequestPublicClassList,
  error
})

export const modifyPublicClass = (key) => ({
  type: ACTION.modifyPublicClass,
  key
})

export const requestPublicClass = (key) => ({
  type: ACTION.requestPublicClass,
  key
})

export const receivePublicClass = (key, json) => {

  let result = json
  
//  alert("PublicClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receivePublicClass,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removePublicClass = (key) => ({
  type: ACTION.removePublicClass,
  key
})

export const errorModifyPublicClass = (key, error) => ({
  type: ACTION.errorModifyPublicClass,
  key,
  error
})

export const errorRequestPublicClass = (key, error) => ({
  type: ACTION.errorRequestPublicClass,
  key,
  error
})

//payment
export const requestPaymentMethod = (key) => ({
  type: ACTION.requestPaymentMethod,
  key
})

export const receivePaymentMethod = (key, json) => {

  let result = json
  
//  alert("TrainingClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receivePaymentMethod,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestPaymentMethod = (key, error) => ({
  type: ACTION.errorRequestPaymentMethod,
  key,
  error
})

// Reducers for Conversation

const publicClassReducer = createReducer(publicClassInitial, {
  [ACTION.requestPublicClass]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyPublicClass]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receivePublicClass]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestPublicClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyPublicClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const paymentMethodReducer = createReducer(paymentMethodInitial, {
  [ACTION.requestPaymentMethod]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyPaymentMethod]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receivePaymentMethod]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestPaymentMethod]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const publicClassListReducer = createReducer(publicClassListInitial, {
  [ACTION.requestPublicClassList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receivePublicClassList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestPublicClassList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function publicClassByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removePublicClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: publicClassReducer(state[key], action)
  }
}

function paymentMethodByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeTrainingClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: paymentMethodReducer(state[key], action)
  }
}

const publicClassByKeyReducer = createReducer({}, {
  [ACTION.requestPublicClass]: publicClassByKeyUpdater,
  [ACTION.modifyPublicClass]: publicClassByKeyUpdater,
  [ACTION.removePublicClass]: publicClassByKeyUpdater,
  [ACTION.receivePublicClass]: publicClassByKeyUpdater,
  [ACTION.errorRequestPublicClass]: publicClassByKeyUpdater,
  [ACTION.errorModifyPublicClass]: publicClassByKeyUpdater,
})

const publicClassPaymentByKeyReducer = createReducer({}, {
  [ACTION.requestPaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.modifyPaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.receivePaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.errorRequestPaymentMethod]: paymentMethodByKeyUpdater
})

// Combine list and records reducer

const publicClassDataReducer = combineReducers({
  list: publicClassListReducer,
  records: publicClassByKeyReducer,
  paymentMethods: publicClassPaymentByKeyReducer,
});

export default publicClassDataReducer