import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const trainingClassInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const trainingClassListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestTrainingClassList: "requestTrainingClassList",
  receiveTrainingClassList: "receiveTrainingClassList",
  errorRequestTrainingClassList: "errorRequestTrainingClassList",
  
  requestTrainingClass: "requestTrainingClass",
  receiveTrainingClass: "receiveTrainingClass",
  modifyTrainingClass: "modifyTrainingClass",
  removeTrainingClass: "removeTrainingClass",
  errorModifyTrainingClass: "errorModifyTrainingClass",
  errorRequestTrainingClass: "errorRequestTrainingClass",

  fetchTrainerIndex: "fetchTrainerIndex",
  requestTrainerIndex: "requestTrainerIndex",
  receiveTrainerIndex: "receiveTrainerIndex",
  errorRequestTrainerIndex: "errorRequestTrainerIndex",
  invalidateTrainerIndex: "invalidateTrainerIndex",
  fetchTrainer: "fetchTrainer",
  requestTrainer: "requestTrainer",
  requestDownload: "requestDownload",
  requestState: "requestState",
  receiveTrainer: "receiveTrainer",
  receiveState: "receiveState",
  receiveCity: "receiveCity",
  errorRequestTrainer: "errorRequestTrainer",
  errorRequestState: "errorRequestState",
  errorRequestCity: "errorRequestCity",
  errorRequestDownload: "errorRequestDownload",
  invalidateTrainer: "invalidateTrainer",
}

export const getTrainingClassList = (state) => {
  return state.client.trainingClass.list
}

export const getTrainingClass = (state, key) => {
  if (key in state.client.trainingClass.records)
    return state.client.trainingClass.records[key]
  return null
}

export const getTrainer = (state, recordKey) => {
  if (recordKey in state.trainer.records)
      return state.trainer.records[recordKey]
  
  return trainerInitial
}

export const requestTrainer = (recordKey, params) => ({
  type: ACTION.requestTrainer,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const requestState = (recordKey, params) => ({
  type: ACTION.requestState,
  recordKey,
  params
});

export const receiveTrainer = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveTrainer,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

export const receiveState = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveState,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestState = (recordKey, params, error) => ({
  type: ACTION.errorRequestState,
  recordKey,
  params,
  error
});

// Async action creator

export const fetchTrainingClassList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getTrainingClassList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/order/"

    let user = getState().alpha.user

    dispatch(requestTrainingClassList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClassList(json)))
      .catch(error => dispatch(errorRequestTrainingClassList(error)))
  }
}

export const fetchTrainingClass = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getTrainingClass(getState(), key)
    // if (res && (res.isFetching || (res.lastFetched  && !refresh)))
    //   return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/order/" + key + "/"
      
    let user = getState().alpha.user
    
    dispatch(requestTrainingClass(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",            
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorRequestTrainingClass(key, error)));
  }
}

export const submit = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/submit/"

    let user = getState().alpha.user
    
    let data = { }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const inviteTrainer = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/invite_trainer/"

    let user = getState().alpha.user
    
    let data = { uid: trainerKey }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const selectProposal = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/select_proposal/"

    let user = getState().alpha.user
    
    let data = { uid: proposalKey }
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/create_order/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const cancelTrainingClass = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/cancel/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))          
  }
}

export const updateTrainingClass = (key, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/"

    let user = getState().alpha.user
      
    dispatch(modifyTrainingClass(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(key, json)))
      .catch(error => dispatch(errorModifyTrainingClass(key, error)))
  }
}

export const fetchAvailableTrainer = (trainingClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainer(getState(), trainingClassKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + trainingClassKey + "/available_trainers/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestTrainer(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainer(trainingClassKey, params, json)))
      .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const downloadProposal = (trainingClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + trainingClassKey + "/download/?document_type=proposal"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const downloadProposalByProposalId = (trainingClassKey, proposalId, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + trainingClassKey + "/download/?document_type=proposal&proposal="+proposalId
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const downloadLetter = (trainingClassKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + trainingClassKey + "/download/?document_type=assignment_letter"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => dispatch(receiveTrainer(trainingClassKey, params, json)))
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const downloadReport = (trainingClassKey, reportUid, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + trainingClassKey + "/download/?document_type=report&report="+reportUid
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainingClassKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const createTrainingClass = (createKey, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/?submit=1"

    let user = getState().alpha.user
    
    // createKey can be anything unique.
    
    dispatch(removeTrainingClass(createKey))
    dispatch(modifyTrainingClass(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainingClass(createKey, json)))
      .catch(error => dispatch(errorModifyTrainingClass(createKey, error)))
  }
}


// Synchronous Action Creators

export const requestTrainingClassList = () => ({
  type: ACTION.requestTrainingClassList,
})

export const receiveTrainingClassList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("trainingClassList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTrainingClassList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainingClassList = (error) => ({
  type: ACTION.errorRequestTrainingClassList,
  error
})

export const modifyTrainingClass = (key) => ({
  type: ACTION.modifyTrainingClass,
  key
})

export const requestTrainingClass = (key) => ({
  type: ACTION.requestTrainingClass,
  key
})

export const receiveTrainingClass = (key, json) => {

  let result = json
  
//  alert("TrainingClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveTrainingClass,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeTrainingClass = (key) => ({
  type: ACTION.removeTrainingClass,
  key
})

export const errorModifyTrainingClass = (key, error) => ({
  type: ACTION.errorModifyTrainingClass,
  key,
  error
})

export const errorRequestTrainingClass = (key, error) => ({
  type: ACTION.errorRequestTrainingClass,
  key,
  error
})

// Reducers for Conversation

const trainingClassReducer = createReducer(trainingClassInitial, {
  [ACTION.requestTrainingClass]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyTrainingClass]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveTrainingClass]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestTrainingClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyTrainingClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const trainingClassListReducer = createReducer(trainingClassListInitial, {
  [ACTION.requestTrainingClassList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainingClassList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestTrainingClassList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function trainingClassByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeTrainingClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: trainingClassReducer(state[key], action)
  }
}

const trainingClassByKeyReducer = createReducer({}, {
  [ACTION.requestTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.modifyTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.removeTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.receiveTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.errorRequestTrainingClass]: trainingClassByKeyUpdater,
  [ACTION.errorModifyTrainingClass]: trainingClassByKeyUpdater,
})

// Combine list and records reducer

const trainingClassDataReducer = combineReducers({
  list: trainingClassListReducer,
  records: trainingClassByKeyReducer,
});

export default trainingClassDataReducer