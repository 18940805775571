import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const dashboardInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const dashboardIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchDashboardIndex: "fetchDashboardIndex",
  requestDashboardIndex: "requestDashboardIndex",
  receiveDashboardIndex: "receiveDashboardIndex",
  errorRequestDashboardIndex: "errorRequestDashboardIndex",
  invalidateDashboardIndex: "invalidateDashboardIndex",
  fetchDashboard: "fetchDashboard",
  requestDashboard: "requestDashboard",
  receiveDashboard: "receiveDashboard",
  errorRequestDashboard: "errorRequestDashboard",
  invalidateDashboard: "invalidateDashboard",
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getDashboardIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.dashboard.indexes) {
    return state.dashboard.indexes[key]
  }
  
  return dashboardIndexInitial
}

export const fetchDashboardIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getDashboardIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
    let user = getState().alpha.user
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/admin/dashboard/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)
        
    dispatch(requestDashboardIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : '',
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveDashboardIndex(path, params, json)))
      .catch(error => dispatch(errorRequestDashboardIndex(path, params, error)));
  }
};

export const getDashboard = (state, recordKey) => {
//   if (recordKey in state.Dashboard.records)
//       return state.Dashboard.records[recordKey]

if (state.dashboard.records)
      return state.dashboard.records
  
  return dashboardInitial
}


export const fetchDashboard = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getDashboard(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/address/api/city/?state="+recordKey+"&country=ID"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestDashboard(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveDashboard(recordKey, params, json)))
      .catch(error => dispatch(errorRequestDashboard(recordKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateDashboardIndex = (path, params) => ({
  type: ACTION.invalidateDashboardindex,
  path
});

export const requestDashboardIndex = (path, params) => ({
  type: ACTION.requestDashboardIndex,
  path,
  params
});

export const receiveDashboardIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveDashboardIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestDashboardIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestDashboardIndex,
  recordKey,
  params,
  error
});

export const invalidateDashboard = (recordKey) => ({
  type: ACTION.invalidateDashboard,
  recordKey
});

export const requestDashboard = (recordKey, params) => ({
  type: ACTION.requestDashboard,
  recordKey,
  params
});

export const receiveDashboard = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveDashboard,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestDashboard = (recordKey, params, error) => ({
  type: ACTION.errorRequestDashboard,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const dashboardIndexReducer = createReducer(dashboardIndexInitial, {
  [ACTION.requestDashboardIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveDashboardIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestDashboardIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateDashboardIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function dashboardIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: dashboardIndexReducer(state[key], action)
  }
}

const dashboardIndexByKeyReducer = createReducer({}, {
  [ACTION.requestDashboardIndex]: dashboardIndexByKeyUpdater,
  [ACTION.receiveDashboardIndex]: dashboardIndexByKeyUpdater,
  [ACTION.errorReceiveDashboardIndex]: dashboardIndexByKeyUpdater,
  [ACTION.invalidateDashboardIndex]: dashboardIndexByKeyUpdater,
})


// Reducers for Product Record

const dashboardReducer = createReducer(dashboardInitial, {
  [ACTION.requestDashboard]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveDashboard]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestDashboard]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateDashboard]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function dashboardByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: dashboardReducer(state[key], action)
  }
}

const dashboardByKeyReducer = createReducer({}, {
  [ACTION.requestDashboard]: dashboardByKeyUpdater,
  [ACTION.receiveDashboard]: dashboardByKeyUpdater,
  [ACTION.errorRequestDashboard]: dashboardByKeyUpdater,
  [ACTION.invalidateDashboard]: dashboardByKeyUpdater,
})

// Combine all catalog reducer

const dashboardDataReducer = combineReducers({
  indexes: dashboardIndexByKeyReducer,
  records: dashboardByKeyReducer,
});

export default dashboardDataReducer