import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const contentRecordInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  data: { }
}

const contentIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: { records: null }
}

// Actions
const ACTION = {
  requestContentIndex: "requestContentIndex",
  receiveContentIndex: "receiveContentIndex",
  errorRequestContentIndex: "errorRequestContentIndex",
  requestContentRecord: "requestContentRecord",
  receiveContentRecord: "receiveContentRecord",
  errorRequestContentRecord: "errorRequestContentRecord",
  modifyContentRecord: "modifyContentRecord",
  errorModifyContentRecord: "errorModifyContentRecord",
}

// Async action creator

export const getContentIndex = (state, modelKey, indexKey='default') => {
  if (modelKey in state.alpha.content) {
    if (indexKey in state.alpha.content[modelKey].indexes)
      return state.alpha.content[modelKey].indexes[indexKey]
  }
  
  return contentIndexInitial
}

export const fetchContentIndex = (modelKey, indexKey, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getContentIndex(getState(), modelKey, indexKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    if (!indexKey)
      indexKey = "default"
  
    let url = process.env.REACT_APP_GOAPP_API_URL +"/content/api/content/"
    if (modelKey && modelKey != "all")
      url += "?model=" + modelKey
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestContentIndex(modelKey, indexKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key, ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContentIndex(modelKey, indexKey, params, json)))
      .catch(error => dispatch(errorRequestContentIndex(modelKey, indexKey, params, error)));
  }
};

export const getContentRecord = (state, modelKey, recordKey) => {
  if (modelKey in state.alpha.content) {
    if (recordKey in state.alpha.content[modelKey].records)
      return state.alpha.content[modelKey].records[recordKey]
  }
  
  return contentRecordInitial
}


export const fetchContentRecord = (modelKey, recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getContentRecord(getState(), modelKey, recordKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/content/api/content/" + recordKey + "/"
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")

    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }    
        
    dispatch(requestContentRecord(modelKey, recordKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key, ...authorization }            
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContentRecord(modelKey, recordKey, params, json)))
      .catch(error => dispatch(errorRequestContentRecord(modelKey, recordKey, params, error)))
  }
};

export const updateContentRecord = (modelKey, recordKey, data) => {
  return (dispatch, getState) => {

    let params = null

    let url = process.env.REACT_APP_GOAPP_API_URL + "/content/api/content/" + recordKey + "/"
    let api_key = process.env.REACT_APP_GOAPP_API_KEY

    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }    
    
    dispatch(modifyContentRecord(modelKey, recordKey, data)); 
    return fetch(url, { 
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'X-API-Key': api_key, 
          'Content-Type': 'application/json',
          ...authorization
        },
        body: JSON.stringify(data)     
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContentRecord(modelKey, recordKey, params, json)))
      .catch(error => dispatch(errorModifyContentRecord(modelKey, recordKey, params, error)))
  }
};

// Synchronous Action Creators for Content Index

export const requestContentIndex = (modelKey, indexKey, params) => ({
  type: ACTION.requestContentIndex,
  modelKey,
  indexKey,
  params
});

export const receiveContentIndex = (modelKey, indexKey, params, json) => {

  let result = {
    count: json.length,
    records: json
  }
//  alert("receiveContentIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveContentIndex,
    modelKey,
    indexKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestContentIndex = (modelKey, indexKey, params, error) => ({
  type: ACTION.errorRequestContentIndex,
  modelKey,
  indexKey,
  params,
  error
});

export const modifyContentRecord = (key) => ({
  type: ACTION.modifyContentRecord,
  key
})

export const requestContentRecord = (modelKey, contentKey, params) => ({
  type: ACTION.requestContentRecord,
  modelKey,
  contentKey,
  params
});

export const receiveContentRecord = (modelKey, contentKey, params, json) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveContentRecord,
    modelKey,
    contentKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestContentRecord = (modelKey, contentKey, params, error) => ({
  type: ACTION.errorRequestContentRecord,
  modelKey,
  contentKey,
  params,
  error
});

export const errorModifyContentRecord = (key, error) => ({
  type: ACTION.errorModifyContentRecord,
  key,
  error
})

// Reducers for Content Index

const contentIndexReducer = createReducer(contentIndexInitial, {
  [ACTION.requestContentIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveContentIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestContentIndex]: (state, action) => {
      alert("Request content index failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function contentIndexByKeyUpdater(state, action) {
  let key = action.indexKey
  return {
    ...state,
    [key]: contentIndexReducer(state[key], action)
  }
}

const contentIndexByKeyReducer = createReducer({}, {
  [ACTION.requestContentIndex]: contentIndexByKeyUpdater,
  [ACTION.receiveContentIndex]: contentIndexByKeyUpdater,
  [ACTION.errorReceiveContentIndex]: contentIndexByKeyUpdater,
})


// Reducers for Content Record

const contentRecordReducer = createReducer(contentRecordInitial, {
  [ACTION.requestContentRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyContentRecord]: (state, action) => {
    return {
      ...state,
      isFetching: true
    }
  },
  [ACTION.receiveContentRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestContentRecord]: (state, action) => {
      alert("Request content record failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
    [ACTION.errorModifyContentRecord]: (state, action) => {
      alert("Request content record failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function contentRecordByKeyUpdater(state, action) {
  let key = action.contentKey
  return {
    ...state,
    [key]: contentRecordReducer(state[key], action)
  }
}

const contentRecordByKeyReducer = createReducer({}, {
  [ACTION.requestContentRecord]: contentRecordByKeyUpdater,
  [ACTION.modifyContentRecord]: contentRecordByKeyUpdater,
  [ACTION.receiveContentRecord]: contentRecordByKeyUpdater,
  [ACTION.errorRequestContentRecord]: contentRecordByKeyUpdater,
  [ACTION.errorModifyContentRecord]: contentRecordByKeyUpdater,
})

// Combine all content reducer

const contentReducer = combineReducers({
  indexes: contentIndexByKeyReducer,
  records: contentRecordByKeyReducer
});

// Content model by key reducer

function contentModelReducer(state={}, action) {

  if (action.type in ACTION) {  
    let modelKey = action.modelKey
    let newState = {
      ...state,
      [modelKey]: contentReducer(state[modelKey], action)
    }
//    alert(JSON.stringify(newState, null, 2))
    return newState
  }
  
  return state
}

export default contentModelReducer
