import { combineReducers } from 'redux'
import course from './reducers/course'
import trainer from './reducers/trainer'
import proposal from './reducers/proposal'
import trainingClass from './reducers/training_class'
import dashboard from './reducers/dashboard'
import location from './reducers/location'
import project from './reducers/project'
import provider from './reducers/provider'

export const rootKey = 'admin'

export const reducer = combineReducers({
  course,
  trainer,
  proposal,
  trainingClass,
  dashboard,
  location,
  project,
  provider
})
