import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const trainerIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchTrainerIndex: "fetchTrainerIndex",
  requestTrainerIndex: "requestTrainerIndex",
  receiveTrainerIndex: "receiveTrainerIndex",
  errorRequestTrainerIndex: "errorRequestTrainerIndex",
  invalidateTrainerIndex: "invalidateTrainerIndex",
  fetchTrainer: "fetchTrainer",
  requestTrainer: "requestTrainer",
  modifyTrainer: "modifyTrainer",
  receiveTrainer: "receiveTrainer",
  errorRequestTrainer: "errorRequestTrainer",
  invalidateTrainer: "invalidateTrainer",
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getTrainerIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.admin.trainer.indexes) {
    return state.admin.trainer.indexes[key]
  }
  
  return trainerIndexInitial
}

export const fetchTrainerIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getTrainerIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    let user = getState().alpha.user
        
    dispatch(requestTrainerIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : '',
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainerIndex(path, params, json)))
      .catch(error => dispatch(errorRequestTrainerIndex(path, params, error)));
  }
};

export const getTrainer = (state, recordKey) => {
  if (recordKey in state.admin.trainer.records)
      return state.admin.trainer.records[recordKey]
  
  return trainerInitial
}


export const fetchTrainer = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainer(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestTrainer(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainer(recordKey, params, json)))
      .catch(error => dispatch(errorRequestTrainer(recordKey, params, error)))
  }
};

export const createTrainer = (createKey = 'new',data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/"
    
    // createKey can be anything unique.
    
    dispatch(modifyTrainer(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainer(createKey, null, json)))
      .then(trainer => {
          onComplete(trainer.result.uid)
      })
      .catch(error => dispatch(errorRequestTrainer(createKey, error)))
  }
}

export const submitTrainer = (key, profileKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/" + key + "/submit/"
    
    // createKey can be anything unique.
    
    dispatch(modifyTrainer(profileKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        }
      })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainer(profileKey, null, json)))
      .catch(error => dispatch(errorRequestTrainer(profileKey,null, error)))
  }
}

export const updateTrainer = (profileKey, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/"+ profileKey +"/"
    
    // createKey can be anything unique.
    
    dispatch(modifyTrainer(profileKey))
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
          dispatch(receiveTrainer(profileKey, null, json))
          onComplete()
      })
      .catch(error => dispatch(errorRequestTrainer(profileKey, error)))
  }
}

export const deleteTrainer = (profileKey, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/" + profileKey +"/"
    
    // createKey can be anything unique.
    
    dispatch(modifyTrainer(profileKey))
    
    return fetch(url, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : ""
        },
      })
      .then(response => {
          if(response.status === 204){
              onComplete()
          }else{
              alert("ERROR")
          }
      })
      .catch(error => error)
  }
}

export const downloadTrainerProfile = (trainerId, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/admin/" + trainerId + "/download/?document_type=trainer_profile"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainerId, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateTrainerIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestTrainerIndex = (path, params) => ({
  type: ACTION.requestTrainerIndex,
  path,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const receiveTrainerIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTrainerIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainerIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainerIndex,
  recordKey,
  params,
  error
});

export const invalidateTrainer = (recordKey) => ({
  type: ACTION.invalidateTrainer,
  recordKey
});

export const requestTrainer = (recordKey, params) => ({
  type: ACTION.requestTrainer,
  recordKey,
  params
});

export const modifyTrainer = (key) => ({
  type: ACTION.modifyTrainer,
  key
})

export const receiveTrainer = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveTrainer,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const trainerIndexReducer = createReducer(trainerIndexInitial, {
  [ACTION.requestTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTrainerIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateTrainerIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function trainerIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: trainerIndexReducer(state[key], action)
  }
}

const trainerIndexByKeyReducer = createReducer({}, {
  [ACTION.requestTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.receiveTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.errorReceiveTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.invalidateTrainerIndex]: trainerIndexByKeyUpdater,
})


// Reducers for Product Record

const trainerReducer = createReducer(trainerInitial, {
  [ACTION.requestTrainer]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.modifyTrainer]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveTrainer]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result,
        lastUpdated: action.receivedAt
      }        
    },
  [ACTION.errorRequestTrainer]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateTrainer]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function trainerByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: trainerReducer(state[key], action)
  }
}

const trainerByKeyReducer = createReducer({}, {
  [ACTION.requestTrainer]: trainerByKeyUpdater,
  [ACTION.receiveTrainer]: trainerByKeyUpdater,
  [ACTION.errorRequestTrainer]: trainerByKeyUpdater,
  [ACTION.invalidateTrainer]: trainerByKeyUpdater,
})

// Combine all catalog reducer

const trainerDataReducer = combineReducers({
  indexes: trainerIndexByKeyReducer,
  records: trainerByKeyReducer,
});

export default trainerDataReducer