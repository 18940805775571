import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const proposalInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const proposalListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestProposalList: "requestProposalList",
  receiveProposalList: "receiveProposalList",
  errorRequestProposalList: "errorRequestProposalList",
  
  requestProposal: "requestProposal",
  receiveProposal: "receiveProposal",
  modifyProposal: "modifyProposal",
  removeProposal: "removeProposal",
  errorModifyProposal: "errorModifyProposal",
  errorRequestProposal: "errorRequestProposal",
  requestDownload: "requestDownload",
}

export const getProposalList = (state) => {
  return state.client.proposal.list
}

export const getProposal = (state, key) => {
  if (key in state.client.proposal.records)
    return state.client.proposal.records[key]
  return null
}

// Async action creator

export const fetchProposalList = (key,refresh=false) => {
  return (dispatch, getState) => {
    let res = getProposalList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/?project="+key

    let user = getState().alpha.user

    dispatch(requestProposalList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProposalList(json)))
      .catch(error => dispatch(errorRequestProposalList(error)))
  }
}

export const fetchProposal = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProposal(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/"
      
    let user = getState().alpha.user
    
    dispatch(requestProposal(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProposal(key, json)))
      .catch(error => dispatch(errorRequestProposal(key, error)));
  }
}

export const acceptProposal = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/accept/"

    let user = getState().alpha.user
    
    let data = { }
    
    dispatch(modifyProposal(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProposal(key, json)))
      .catch(error => dispatch(errorModifyProposal(key, error)))          
  }
}

export const rejectProposal = (key) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/reject/"

    let user = getState().alpha.user
    
    let data = {}
    
    dispatch(modifyProposal(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProposal(key, json)))
      .catch(error => dispatch(errorModifyProposal(key, error)))          
  }
}

export const askRevisionProposal = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/request_revision/"

    let user = getState().alpha.user
    
    // let data = {}
    
    dispatch(modifyProposal(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProposal(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyProposal(key, error)))          
  }
}

export const downloadProposal = (key, onComplete) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/download/?document_type=proposal"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status === 200){
            onComplete()
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status === 404){
            throw new Error("Not Found")
          }
          else if (response.status === 500){
            alert("Unexpected token A")
          }
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

export const updateProposal = (key, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/proposal/" + key + "/"

    let user = getState().alpha.user
      
    dispatch(modifyProposal(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProposal(key, json)))
      .catch(error => dispatch(errorModifyProposal(key, error)))
  }
}

// Synchronous Action Creators

export const requestProposalList = () => ({
  type: ACTION.requestProposalList,
})

export const receiveProposalList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("proposalList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProposalList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProposalList = (error) => ({
  type: ACTION.errorRequestProposalList,
  error
})

export const modifyProposal = (key) => ({
  type: ACTION.modifyProposal,
  key
})

export const requestProposal = (key) => ({
  type: ACTION.requestProposal,
  key
})

export const receiveProposal = (key, json) => {

  let result = json
  
//  alert("Proposal Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProposal,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeProposal = (key) => ({
  type: ACTION.removeProposal,
  key
})

export const requestDownload = (key) => ({
  type: ACTION.requestDownload,
  key
});

export const errorModifyProposal = (key, error) => ({
  type: ACTION.errorModifyProposal,
  key,
  error
})

export const errorRequestProposal = (key, error) => ({
  type: ACTION.errorRequestProposal,
  key,
  error
})

// Reducers for Conversation

const proposalReducer = createReducer(proposalInitial, {
  [ACTION.requestProposal]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyProposal]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveProposal]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestProposal]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyProposal]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const proposalListReducer = createReducer(proposalListInitial, {
  [ACTION.requestProposalList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProposalList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestProposalList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function proposalByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeProposal) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: proposalReducer(state[key], action)
  }
}

const proposalByKeyReducer = createReducer({}, {
  [ACTION.requestProposal]: proposalByKeyUpdater,
  [ACTION.modifyProposal]: proposalByKeyUpdater,
  [ACTION.removeProposal]: proposalByKeyUpdater,
  [ACTION.receiveProposal]: proposalByKeyUpdater,
  [ACTION.errorRequestProposal]: proposalByKeyUpdater,
  [ACTION.errorModifyProposal]: proposalByKeyUpdater,
})

// Combine list and records reducer

const proposalDataReducer = combineReducers({
  list: proposalListReducer,
  records: proposalByKeyReducer,
});

export default proposalDataReducer