import { createStore, applyMiddleware, compose } from 'redux'

import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { rootReducer } from './reducer'
import conversationMiddleware from './middleware/conversation'

const enhancers = []
const middleware = [thunk, conversationMiddleware, logger]
  
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default () => {
  const store = createStore(rootReducer, {}, composedEnhancers)
  return { store }
}